@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap);
:root {
  --fc-list-event-hover-bg-color: #2d3d508c;
}
.fc-theme-standard .fc-list-day-cushion {
  background: var(--fc-button-bg-color);
  color: white;
}

.fc-daygrid-event {
  background: #2d3d50;
}

a:visited {
  text-decoration: none;
}

html,
body,
#root,
.app,
.content {
  font-family: 'Source Sance Pro', sans-serif;
}

.full-width {
  width: 100%;
}

.form-error-label {
  font-family: Source Sans Pro, sans-serif;
  font-weight: 400;
  font-size: 0.6428571428571428rem;
  line-height: 1.66;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
  color: #f44336;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

