:root {
  --fc-list-event-hover-bg-color: #2d3d508c;
}
.fc-theme-standard .fc-list-day-cushion {
  background: var(--fc-button-bg-color);
  color: white;
}

.fc-daygrid-event {
  background: #2d3d50;
}

a:visited {
  text-decoration: none;
}
